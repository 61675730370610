import React from "react";
import Arrow from "../images/icons/arrow.svg";

const AriadneGroup = () => {
  return (
    <>
      <section class="section section--group pb-0" aria-label="Ariadne Group, The Digital Compass">
        <div class="container-xl">
          <div class="row">
            <div class="col-12 col-md-6">
              <h2 class="section__title section__title--group">Ariadne Group, <span class="d-block">The Digital Compass</span></h2>
            </div>
            <div class="col-12 col-md-6">
              <div class="section__text">
                <div class="d-flex my-4">
                  <div className="section__arrow-wrapper d-none d-md-block">
                    <Arrow className="section__arrow" />
                  </div>
                  <div>
                    <a href="https://www.ariadnegroup.it/" title="Ariadne Group" class="d-inline-block mb-4" target="_blank" rel="noopener noreferrer">
                      <svg viewBox="0 0 1061 173" xmlns="http://www.w3.org/2000/svg" width="260">
                        <g class="monogramma-dgs">
                          <path fill="#1C0000" d="M167.86 85.45C167.86 73.32 158.03 63.49 145.9 63.49C133.77 63.49 123.94 73.32 123.94 85.45C123.94 97.58 133.77 107.41 145.9 107.41C158.03 107.41 167.86 97.58 167.86 85.45Z"></path>
                          <path fill="#1C0000" d="M53.07 51.31C57.73 46.84 61.4 41.02 62.77 34.67C63.84 29.73 62.61 24.84 63.35 19.89C64.11 14.82 66.48 10.11 70.24 6.60996C84.97 -7.06004 109.63 4.77998 108.21 24.81C107.53 34.43 100.55 42.8 91.18 45.16C86.96 46.23 83.25 45.33 79.06 45.14C75.53 44.98 71.87 45.83 68.58 47.02C50.51 53.52 40.71 74 44.53 92.36C47.81 108.1 62.52 124.33 79.84 122.27C89.83 121.09 99.65 126.47 103.65 135.79C107.28 144.25 105.3 154.34 98.76 160.81C92.2 167.29 82.08 169.15 73.64 165.41C64.23 161.23 59.38 151.38 60.48 141.35C62.01 127.35 54.56 114.94 42.33 108.4C38.23 106.2 33.64 104.77 28.95 104.86C23.76 104.96 19.18 105.36 14.27 103.17C5.42004 99.23 0.0600355 89.72 1.10004 80.11C2.15004 70.46 9.60003 62.26 19.12 60.29C24.12 59.26 28.75 61.14 33.71 60.67C38.21 60.24 42.51 58.44 46.37 56.16C48.72 54.77 51.08 53.2 53.06 51.3L53.07 51.31Z"></path>
                          <path fill="#1C0000" d="M100.89 68.4C105.93 73.69 107.94 80.96 106.77 88.1C106.03 92.61 107.21 97.19 108.74 101.45C113.16 113.75 123.78 121 136.48 123.78C162.26 129.41 187.1 108.34 185.72 81.98C185.04 69.08 177.98 56.36 166.61 49.91C162.33 47.48 157.2 45.62 152.22 45.63C149.58 45.63 147.06 46.23 144.39 45.97C140.87 45.62 137.45 44.45 134.47 42.55C123.35 35.47 120.64 19.85 128.79 9.48002C136.79 -0.70998 152.22 -2.05999 161.82 6.66001C167.2 11.55 169.67 18.74 169.06 25.93C168.51 32.46 168.72 38.13 171.7 44.11C175.18 51.08 180.85 56.57 187.77 60.1C190.46 61.47 193.33 62.5 196.31 62.99C199.93 63.58 203.44 62.81 207.07 63.03C213.92 63.44 220.33 66.87 224.32 72.49C232.01 83.32 228.44 98.7 217.01 105.31C213.92 107.1 210.41 108.16 206.83 108.3C203.35 108.43 200.05 107.23 196.57 107.43C190.68 107.75 185.06 110.48 180.28 113.8C173.7 118.36 168.72 125.2 166.86 133.05C165.22 139.97 167.15 146.97 164.4 153.73C161.83 160.06 156.29 164.84 149.71 166.63C136.53 170.22 123.09 161.11 121.43 147.58C121.02 144.27 121.63 141.12 121.72 137.83C121.81 134.49 121.23 131.17 120.14 128.02C118.64 123.67 116.2 120.92 113.15 117.46C107.53 111.1 98.87 106.21 90.27 106.28C87.01 106.31 83.93 106.86 80.66 106.3C77.14 105.7 73.76 104.27 70.9 102.13C65.63 98.18 62.31 91.99 61.91 85.43C61.13 72.53 71.73 61.31 84.66 61.4C90.76 61.44 96.68 64 100.89 68.42"></path>
                        </g>
                        <g class="ariadne-label">
                          <path fill="#1C0000" stroke="#1C0000" stroke-width="6" stroke-miterlimit="10" d="M303.62 16.05H299.1L257.13 116.13L256.81 116.78H264.88L279.41 82.24H323.32L337.53 116.46V116.78H345.92L303.63 16.05H303.62ZM301.03 28.97C301.68 30.58 302.64 33.49 305.55 39.62L320.72 75.78H281.98L297.15 39.3C298.44 36.39 299.73 33.17 301.02 28.97H301.03Z"></path>
                          <path fill="#1C0000" stroke="#1C0000" stroke-width="6" stroke-miterlimit="10" d="M433.08 103.54C430.5 100.63 423.72 91.92 413.06 77.71C416.93 74.8 420.49 71.25 423.39 66.73C426.62 62.21 428.23 57.04 428.23 51.23C428.23 42.84 425.32 36.7 419.84 32.18C414.35 27.66 406.93 25.4 398.53 25.4C393.04 25.4 386.26 25.72 378.19 26.37H377.87V116.77H384.97V79.97C392.07 80.29 399.18 80.62 406.6 80.62C417.25 95.47 424.36 104.83 427.58 108.06C430.81 111.61 434.04 113.87 437.27 115.49C440.5 116.78 444.7 117.75 450.18 117.75H450.5L450.82 112.58H450.5C444.04 111.93 438.23 108.71 433.07 103.54H433.08ZM420.81 50.92C420.81 57.05 418.87 62.87 414.68 67.39C410.48 72.23 406.93 74.49 403.38 74.49C397.89 74.49 391.76 74.17 385.3 73.84V32.19C390.14 31.87 394.34 31.54 397.89 31.54C404.67 31.54 410.48 33.15 414.68 36.38C418.55 39.61 420.81 44.45 420.81 50.91V50.92Z"></path>
                          <path fill="#1C0000" stroke="#1C0000" stroke-width="6" stroke-miterlimit="10" d="M483.12 25.74H476.02V116.78H483.12V25.74Z"></path>
                          <path fill="#1C0000" stroke="#1C0000" stroke-width="6" stroke-miterlimit="10" d="M552.22 25.74H547.38L509.61 116.14L509.29 116.79H516.72L529.63 85.47H569.34L582.25 116.46V116.78H590.32L552.22 25.74ZM567.07 79.33H532.85L546.41 46.4C547.38 43.82 548.67 40.91 549.64 37.04C550.29 38.98 551.58 42.21 553.51 46.4L567.07 79.33Z"></path>
                          <path fill="#1C0000" stroke="#1C0000" stroke-width="6" stroke-miterlimit="10" d="M648.11 25.09C638.1 25.09 629.38 25.41 621.96 26.06H621.64V116.78H621.96C631.97 117.75 641.65 118.07 651.02 118.07C664.58 118.07 675.88 114.2 684.27 106.77C692.99 99.34 697.18 88.04 697.18 73.19C697.18 58.34 692.34 46.72 682.97 38C673.93 29.28 661.99 25.09 648.1 25.09H648.11ZM689.75 73.2C689.75 85.79 686.2 95.48 679.1 101.93C672 108.06 662.63 111.29 650.69 111.29C641.65 111.29 634.22 110.97 628.74 110.32V31.87C633.26 31.55 639.72 31.22 647.79 31.22C659.41 31.22 669.42 35.09 677.49 42.52C685.88 50.27 689.76 60.6 689.76 73.19L689.75 73.2Z"></path>
                          <path fill="#1C0000" stroke="#1C0000" stroke-width="6" stroke-miterlimit="10" d="M808.88 91.6C808.88 97.09 808.88 100.96 809.2 103.22C808.88 102.57 808.23 101.93 807.26 100.64C805.32 98.38 803.39 95.8 801.45 93.54L742.37 25.42H738.82V116.79H745.92V51.58C745.92 46.09 745.92 41.89 745.6 39.63L752.38 48.02L812.75 117.11H816.3V25.74H809.2V91.6H808.88Z"></path>
                          <path fill="#1C0000" stroke="#1C0000" stroke-width="6" stroke-miterlimit="10" d="M869.58 110V70.29H901.86V64.16H869.58V32.2H905.41V25.74H862.48V116.78H906.71V110H869.58Z"></path>
                        </g>
                        <g class="group-label">
                          <path fill="#1C0000" d="M640.66 166.2C639.02 167.19 636.71 167.84 634.08 167.84C631.45 167.84 629.15 166.85 627.5 165.21C625.53 163.57 624.87 160.93 624.87 157.97C624.87 155.01 625.86 152.71 627.83 150.73C629.8 149.09 632.11 148.1 634.74 148.1C636.06 148.1 637.37 148.43 638.69 148.76C639.68 149.09 640.99 149.75 641.98 150.73L644.61 147.11C641.98 144.81 638.69 143.49 634.74 143.49C630.79 143.49 627.5 144.81 624.54 147.77C621.58 150.4 620.26 154.02 620.26 157.97C620.26 161.92 621.58 165.53 624.54 168.17C627.17 170.8 630.79 172.12 634.74 172.12C639.67 172.12 643.29 170.8 645.92 167.84V158.63H641.32V166.19H640.66V166.2Z"></path>
                          <path fill="#1C0000" d="M707.31 153.37C707.31 150.08 706.32 148.11 704.68 146.46C702.71 145.14 699.75 144.49 695.47 144.49H685.27V171.79H689.87V162.58H696.12L702.7 171.79H708.62L701.38 161.59C705 160.6 707.3 157.97 707.3 153.37H707.31ZM695.47 158.3H689.55V148.43H695.47C698.1 148.43 699.75 148.76 700.73 149.42C701.71 150.08 702.05 151.39 702.05 153.04C702.05 154.69 701.72 156 700.73 156.99C700.07 157.98 698.1 158.31 695.47 158.31V158.3Z"></path>
                          <path fill="#1C0000" d="M758.54 143.5C754.26 143.5 750.98 144.82 748.02 147.45C745.06 150.08 743.74 153.7 743.74 157.65C743.74 161.6 745.06 165.21 748.02 167.85C750.98 170.48 754.27 171.8 758.54 171.8C762.81 171.8 766.1 170.48 769.06 167.85C772.02 165.22 773.34 161.6 773.34 157.65C773.34 153.7 772.02 150.09 769.06 147.45C766.43 144.82 762.81 143.5 758.54 143.5ZM765.77 164.88C763.8 166.85 761.49 167.84 758.86 167.84C756.23 167.84 753.93 166.85 751.95 164.88C749.98 162.91 748.99 160.6 748.99 157.64C748.99 155.01 749.98 152.38 751.95 150.4C753.92 148.43 756.23 147.44 758.86 147.44C761.49 147.44 763.79 148.43 765.77 150.4C767.74 152.37 768.73 154.68 768.73 157.64C768.4 160.6 767.41 162.9 765.77 164.88Z"></path>
                          <path fill="#1C0000" d="M828.7 159.62C828.7 162.25 828.04 164.22 826.73 165.54C825.41 166.86 823.77 167.84 821.47 167.84C819.17 167.84 817.52 167.18 816.21 165.54C814.89 164.22 814.24 161.92 814.24 159.62V144.16H809.64V159.62C809.64 163.57 810.63 166.53 812.93 168.83C815.23 171.13 817.86 172.12 821.48 172.12C824.77 172.12 827.73 171.13 830.03 168.83C832.33 166.53 833.32 163.57 833.32 159.62V144.16H828.72V159.62H828.7Z"></path>
                          <path fill="#1C0000" d="M881.42 144.16H871.88V171.79H876.48V163.57H881.41C885.36 163.57 888.65 162.91 890.29 161.27C892.26 159.63 893.25 157.32 893.25 154.03C893.25 150.74 892.26 148.11 890.29 146.47C888.32 144.83 885.69 144.17 881.41 144.17L881.42 144.16ZM887.34 157.97C886.35 158.96 884.71 159.61 882.08 159.61H876.82V148.43H881.42C884.05 148.43 885.7 148.76 887.01 149.75C888.33 150.41 888.65 152.05 888.65 153.7C888.65 155.35 888.32 156.99 887.33 157.98L887.34 157.97Z"></path>
                        </g>
                        <g class="monogramma-ariadne">
                          <path fill="#1C0000" d="M927.78 172.52H991.07L1060.79 23.31H997.51L927.78 172.52Z"></path>
                          <path fill="#1C0000" d="M992.86 8.04999H975.78L933.56 97.91H950.64L992.86 8.04999Z"></path>
                        </g>
                      </svg>
                    </a>
                    <p>
                      Ariadne eLearning è una divisione di Ariadne Group, organizzazione che si compone di diverse unità di business con solide competenze verticali nel settore Digital.<br/>
                      Tutte le società del Gruppo sono coordinate da un dipartimento PMO, la cui visione globale assicura una gestione completa dei processi, con un approccio rigoroso, specializzato e agile, per garantire risultati senza compromessi.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AriadneGroup;
