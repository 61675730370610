import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from './link'
import slug from 'slug';

const PageBox = ({ data, index, snodo, page, hideimg, hideicn }) => {
  let eurl = snodo ?`/${slug(snodo)}/`:null;
  eurl = page ? eurl.concat(`${slug(page)}/`): eurl;
  const { icona, titolo, sommario } = data;

  return (
    <div className={`lanci lanci--${index===1?'center':'extremes'} d-flex flex-column text-center`}>
      {!hideimg && (
      <div className={`lanci__icon${(!hideicn)?' lanci__icon--graphics':''}`}>
        <GatsbyImage  image={getImage(icona)} alt={titolo} />
      </div>
      )}
      <div className="lanci__title">{titolo}</div>
      <div className="lanci__text" dangerouslySetInnerHTML={{ __html: sommario.childMarkdownRemark.html }}></div>
      {!!eurl && (<Link to={eurl} title={titolo} className="lanci__link mt-auto">Scopri di più</Link>)}
    </div>
  );
}

export default PageBox;