import React, { useRef, useCallback } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Scrollspy from 'react-scrollspy'
import { Link as ScrollLink } from 'react-scroll'
import { createParagrafoId } from '../utility/entity'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ContattiInterna from '../components/contatti-interna'
import AutoAffix from '../components/affix/AutoAffix'
import AriadneGroup from "../components/ariadne-group"
// import Aziende from "../components/aziende"


const PaginaInterna = ({ location, data, pageContext: { breadcrumb } }) => {
  const { page, parentPage = {} } = data;
  const { titolo: title, testoHero: subtitle } = page;
  const heroDesktop = page.immagineHero || parentPage.immagineHero;
  const heroMobile = page.heroMobile || parentPage.heroMobile;
  const scrollRef = useRef();
  const getScrollContainer = useCallback(() => scrollRef.current, []);

  return (
    <Layout location={location} hero={{ title, subtitle, heroDesktop, heroMobile, breadcrumb, className: 'hero--small' }}>
      <SEO title={page.titolo} seo={page.seo} image={heroDesktop} />
      <article className="section section--article section--interna">
        <div className="container">
          <div className="row">
            <div className={`col-12${page.paragrafi ? " col-md-8" : ""}`}>
              <div ref={scrollRef} className={`${page.paragrafi ? "mb-md-5 pb-md-5" : "py-5"}`}>
                {page.intro && (
                  <div className={`${page.paragrafi ? "section__claim" : "section__text"}`}
                    dangerouslySetInnerHTML={{ __html: page.intro.childMarkdownRemark.html }}></div>
                )}
                {page.paragrafi && page.paragrafi.map(paragrafo => (
                  <section id={createParagrafoId(paragrafo)} className="section section--anchor pb-0" key={paragrafo.titolo}>
                    {paragrafo.immagine && (
                      <div className="image-graphics image-graphics--interna">
                        <GatsbyImage image={getImage(paragrafo.immagine)} alt={paragrafo.titolo} />
                      </div>
                    )}
                    <h2 className="section__paragraph-title mb-4">{paragrafo.titolo}</h2>
                    <div className="section__paragraph-text"
                      dangerouslySetInnerHTML={{ __html: paragrafo.testo.childMarkdownRemark.html }}></div>
                  </section>
                ))}
              </div>
            </div>
            {page.paragrafi && (
              <div className="col-md-4 pl-5 d-none d-md-block">
                <AutoAffix affixClassName="sidebar sidebar--affix" topClassName="sidebar sidebar--affix-top" bottomClassName="sidebar sidebar--affix-bottom"
                  viewportOffsetTop={100} container={getScrollContainer}>
                  <div>
                    <nav className="right-nav">
                      <Scrollspy items={page.paragrafi.map(createParagrafoId)} offset={-100}
                        className="" currentClassName="active">
                        {page.paragrafi.map(paragrafo => (
                          <li key={paragrafo.titolo} className="right-nav__item">
                            <ScrollLink href={`#${createParagrafoId(paragrafo)}`} to={createParagrafoId(paragrafo)} smooth={true}
                              className="right-nav__link" title={paragrafo.titolo}>
                              {paragrafo.titolo}
                            </ScrollLink>
                          </li>
                        ))}
                      </Scrollspy>
                    </nav>
                  </div>
                </AutoAffix>
              </div>
            )}
          </div>
        </div>
      </article>

      {/* {page.aziende && <Aziende />} */}
      {page.aziende && <AriadneGroup />}
      
      <div className="section">
        <div className="container">
          <ContattiInterna />
        </div>
      </div>
    </Layout>
  );
}

export default PaginaInterna;
export const query = graphql`
query PaginaInternaQuery($contentful_id: String!, $parent_id: String!){
  page: contentfulPaginaInterna(contentful_id: { eq: $contentful_id}) {
    titolo
    testoHero
    seo {
      ...SeoFragment
    }
    immagineHero {
      gatsbyImageData(layout: FULL_WIDTH, quality: 60, placeholder: TRACED_SVG)
    }
    heroMobile: immagineHero {
      gatsbyImageData(width: 576, layout: FULL_WIDTH, quality: 60, placeholder: TRACED_SVG)
    }
    intro {
      childMarkdownRemark {
        html
      }
    }
    paragrafi {
      titolo
      immagine {
        gatsbyImageData(layout: CONSTRAINED, quality: 60, placeholder: TRACED_SVG)
      }
      testo {
        childMarkdownRemark {
          html
        }
      }
    }
    aziende
  }
  parentPage: contentfulPaginaSnodo(contentful_id: { eq: $parent_id}) {
    immagineHero {
      ...HeroContentfulDesktop
    }
    heroMobile {
      ...HeroContentfulMobile
    }
  }
}`