import React from 'react'
import { Link } from './link'
import Linkedin from '../images/icons/linkedin-in-brands.svg'
import Facebook from '../images/icons/facebook-f-brands.svg'
import Youtube from '../images/icons/youtube.svg'
import Instagram from '../images/icons/instagram-brands.svg'

const Footer = () => (
  <>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="footer-group d-flex justify-content-between align-items-center">
          <div>Ariadne eLearning è parte di <em>Ariadne Group</em></div>
          <a href="https://www.ariadnegroup.it" title="Ariadne Group" target="_blank" rel="noopener noreferrer" className="btn-link mt-0">Vai al sito</a>
        </div>
      </div>
    </div>
  </div>
  <div className="footer">
    <div className="container">
      <div className="row mt-2 pt-3">
        <div className="col-md-6 col-lg-4">
          <div className="footer__box">
            <div className="footer__head">Sede legale</div>
            <p>
              Via Visconti di Modrone, 11 - 20122 Milano<br />
              Ariadne eLearning S.r.l. a socio unico<br />
              C.F. e Registro Imprese: 11659010968<br />
              Partita IVA: 11659010968<br />
              Iscritta al Registro Imprese di Milano<br />
              REA: MI-2617350 - Cap. Soc. 25.000,00 € i. v.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 mt-3 mt-md-0">
          <div className="footer__box">
            <div className="footer__head">Uffici</div>
            <p>
              <strong>Trivolzio (PV)</strong><br />
              Via Giovanni Perotti 4, 27020
            </p>
            <p>
              <strong>Avellino (AV)</strong><br />
              Via Giuseppe Zigarelli 43, 83100<br />
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 mt-4 mt-lg-0">
          <div className="footer__box">
            <div className="footer__head">Contatti</div>
            <div>
              <a id="conv-tracking-tel" title="telefono" href="tel:+390382408911" className="mb-2">+39 0382 408911</a>
            </div>
            <div>
              <a id="conv-tracking-mail" title="email" href="mailto:info@ariadnelearning.it">info@ariadnelearning.it</a>
            </div>
            {/* <Link to="/contatti/" className="btn-link">Contattaci</Link> */}
            <p className="footer__social mt-auto mb-2">
              <a href="https://www.linkedin.com/company/ariadnegroup/" title="Linkedin" className="footer__link"
                target="_blank" rel="noopener noreferrer">
                <span className="ico-svg"><Linkedin /></span><span className="hidden-xs-down"></span>
              </a>
              <a href="https://www.facebook.com/AriadneGroup" title="Facebook" className="footer__link"
                target="_blank" rel="noopener noreferrer">
                <span className="ico-svg"><Facebook /></span><span className="hidden-xs-down"></span>
              </a>
              <a href="https://www.instagram.com/ariadnegroup/" title="Instagram" className="footer__link"
                target="_blank" rel="noopener noreferrer">
                <span className="ico-svg"><Instagram /></span><span className="hidden-xs-down"></span>
              </a>
              <a href="https://www.youtube.com/channel/UC7_Z0F7wvMmzuYxa0OAq-Zg" title="Youtube" className="footer__link"
                target="_blank" rel="noopener noreferrer">
                <span className="ico-svg"><Youtube /></span><span className="hidden-xs-down"></span>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="footer__policy">
            <Link to="/privacy/">Privacy policy</Link> - <Link to="/governance-compliance/">Governance e Compliance</Link>
          </p>
        </div>
      </div>
    </div>
  </div>
  </>
);

export default Footer
