import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PageBox from '../components/page-box'
// import slug from 'slug';
import { Link } from '../components/link'

const hero = {
  className: "hero--big hero--overlay",
  scrollDown: false,
  squareBox: false
};

const IndexPage = ({ data, location }) => {
  const { titolo, titoloInPagina, heroDesktop, heroMobile, 
    testoSezione1, ctaSezione1, paginaLinkCta, boxLanci, paginePerLanciSezione2, 
    testoSezione2  } = data.home;
  
  const { titolo: tioloFasciaLoghi, logo: loghi } = data.loghi;
 
  return (
    <Layout location={location} hero={{ ...hero, title: titolo, subtitle: titoloInPagina, heroDesktop, heroMobile }}>
      <SEO title={titolo} description={hero.subtitle} />

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-5">
            {/* <div className="section__sopratitolo">{titoloSezione1}</div> */}
              <div className="section__title section__title--centered">{testoSezione1}</div>
              {!!ctaSezione1 &&
                <Link className="btn-link" to={paginaLinkCta}>{ctaSezione1}</Link>
              }
            </div>
          </div>
          <div className="row">
            {boxLanci.map((item, index) => 
              <div key={index} className="col-12 col-lg-4">
                <PageBox key={index} data={item} index={index} hideicn={true}/>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="section__title section__title--centered">{tioloFasciaLoghi}</div>
                </div>
              </div>
              <div className="image-graphics">
                <div className="box-clients">
                  <div className="row">
                    {loghi.map((item,index) => 
                      <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                        <div className="box-clients__item text-center">
                          <GatsbyImage index={index} image={getImage(item)} alt={item.title} className="box-clients__img" /> 
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-5">
              {/* <div className="section__sopratitolo">{titoloSezione2}</div> */}
              <div className="section__title section__title--centered">{testoSezione2}</div>
            </div>
          </div>
          <div className="row"> 
            {paginePerLanciSezione2.map((item, index) => 
              <div key={index} className="col-12 col-lg-4">
                <PageBox key={index} snodo={item.titolo} data={item.boxLancio} hideimg={true} />
              </div>
            )}
          </div>
        </div>
      </div>
      
    </Layout>
  );
}
export default IndexPage;

export const GatsbyContentfulFluid_withWebp_tracedSVG = graphql`
  fragment GatsbyContentfulFluid_withWebp_tracedSVG on ContentfulFluid {
    tracedSVG
    aspectRatio
    src
    srcSet
    sizes
    srcWebp
    srcSetWebp
  }
`;

export const query = graphql`query HomeQuery{
  home: contentfulPaginaHomepage(contentful_id: { eq: "6cmMCQv79cobCZaQzvs9B4" }) {
    titolo
    titoloInPagina
    titoloSezione1
    testoSezione1
    ctaSezione1
    paginaLinkCta
    boxLanci {
      icona {
        gatsbyImageData(layout: FIXED, width: 80, quality: 100)
      }
      titolo
      sommario {
        childMarkdownRemark {
          html
        }
      }
    }
    testoSezione2
    titoloSezione2
    paginePerLanciSezione2 {
      __typename
      ... on ContentfulPaginaSnodo {
        titolo
        boxLancio {
          icona {
            gatsbyImageData(layout: FIXED, width: 80, quality: 100)
          }
          titolo
          sommario {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    heroDesktop: hero {
      ...HeroContentfulDesktop
    }
    heroMobile {
      ...HeroContentfulMobile
    }
  }
  sfondo: file(relativePath: { eq: "img_home-nodots.png" }) {
    childImageSharp {
      gatsbyImageData(width: 350, layout: CONSTRAINED, quality: 80, placeholder: NONE)
    }
  }
  loghi: contentfulElencoLoghi(contentful_id: {eq: "5lqN4Io0R4rO1vbfhFTbTI"}) {
    titolo
    logo {
      title
			gatsbyImageData(width: 192, layout: CONSTRAINED, quality: 100, placeholder: BLURRED)
    }
  }
}`;
