
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FormContatti from '../components/form-contatti';


const hero = {
  title: "Contatti",
  subtitle: "Scrivici per raccontarci le tue esigenze e trovare insieme la soluzione migliore!",
  className: "hero--small",
  breadcrumb: [
    { title: 'Home', link: '/' },
    { title: 'Contatti' }
  ]
};

const Contatti = ({ data, location }) => {
  const { seo, heroDesktop, heroMobile, testo } = data.page;
  return (
    <Layout location={location} hero={{ ...hero, heroDesktop, heroMobile }}>
      <SEO title={`Contatti`} seo={seo} />
      <section className="section">
        <div className="container">
          <FormContatti />
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="section__title">Come raggiungerci</h2>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12">
              <div dangerouslySetInnerHTML={{ __html: testo.childMarkdownRemark.html }}></div>
              {/* <div className="row">
                <div className="col-sm-12 col-lg-6 mb-4">
                  <div className="section__paragraph-text" dangerouslySetInnerHTML={{ __html: testo.childMarkdownRemark.html }}></div>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <iframe allowFullScreen="" title="Mappa" frameBorder="0" height="450" src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d2808.3739624671693!2d9.046081400883189!3d45.260450828996525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e2!4m5!1s0x4786c1648d0a7281%3A0xc42f09ea73f92fe3!2sAriadne%20Srl%2C%20Via%20Giovanni%2C%20Via%20Perotti%2C%204%2C%2027020%20Trivolzio%20PV!3m2!1d45.2611436!2d9.0484116!4m3!3m2!1d45.259938!2d9.0490342!5e0!3m2!1sit!2sit!4v1568037080313!5m2!1sit!2sit" style={{ border: '0' }} width="100%"></iframe>
                </div>
              </div> */}
              {/*
              <div className="row">
                <div className="col-sm-12 col-lg-6 mb-4">
                  <div className="section__paragraph-text" dangerouslySetInnerHTML={{ __html: testo.childMarkdownRemark.html }}></div>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <iframe allowFullScreen="" title="Mappa" frameBorder="0" height="450" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3015.1263070203117!2d14.785768715411127!3d40.91297637931055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x133bcc501c92302f%3A0x5723e9cae3df2ffd!2sVia%20Giuseppe%20Zigarelli%2C%2043%2C%2083100%20Avellino%20AV!5e0!3m2!1sit!2sit!4v1616498993053!5m2!1sit!2sit" style={{ border: '0' }} width="100%"></iframe>
                </div>
              </div>
              */}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Contatti;
export const query = graphql`{
  page: contentfulPaginaGenerica(contentful_id: { eq: "3Ds0SIYxgPe7zdRQW7xoom" }) {
    heroDesktop: hero {
      ...HeroContentfulDesktop
    }
    heroMobile: hero {
      ...HeroContentfulMobile
    }
    testo {
      childMarkdownRemark {
        html
      }
    }
  }
}`;


